import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import graph from './resources/images/graph.png';
import szseHeader from './resources/images/szse-header.png'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { services } from './services/services';
import { PeriodType } from './mytype/period';

const shenMorningAnnounceDate = moment().format('YYYY-MM-DD 10:25:00'); // 09.55 close
const shenEveningAnnounceDate = moment().format('YYYY-MM-DD 14:05:00'); // 14.15 close

const App = () => {

  const [positiveOrNegative, setPositiveOrNegative] = useState("+");
  const [positiveOrNegativeLast, setPositiveOrNegativeLast] = useState("+");
  const [positiveOrNegativeChange, setPositiveOrNegativeChange] = useState("+");
  const [positiveOrNegativePercent, setPositiveOrNegativePercent] = useState("+");
  const [upNumber, setUpNumber] = useState(25000.00);
  const [downNumber, setDownNumber] = useState(0.00);
  const [changedPercent, setChangedPercent] = useState(0.00);
  const [openNumber, setOpenNumber] = useState(0.00);
  const [prevClose, setPrevClose] = useState(0.00);
  const [statMin, setStatMin] = useState(0.00);
  const [statMax, setStatMax] = useState(0.00);
  const [volumn10Bil, setVolumn10Bil] = useState(0.00);
  const [volumn100Bil, setVolumn100Bil] = useState(0.00);
  const [shenzhenMorningStat, setShenzhenMorningStat] = useState<PeriodType[]>([]);
  const [shenzhenEveningStat, setShenzhenEveningStat] = useState<PeriodType[]>([]);
  const [shenzhenMorningCurrent, setShenzhenMorningCurrent] = useState<PeriodType | null>(null);
  const [shenzhenEveningCurrent, setShenzhenEveningCurrent] = useState<PeriodType | null>(null);

  const getShenzhenMorningStatData = useCallback(async () => {
    // console.log("getShenzhenMorningStatData");

    const response = await services.getStatistics(16);
    setShenzhenMorningStat(response.data);
  }, []);

  const getShenzhenEveningStatData = useCallback(async () => {
    // console.log("getShenzhenEveningStatData");

    const response = await services.getStatistics(17);
    setShenzhenEveningStat(response.data);
  }, []);

  useEffect(() => {

    getShenzhenMorningStatData();
    getShenzhenEveningStatData();

    shenzhenMorningData();
    shenzhenEveningData();

    // every 5 min
    triggerData();
    const interval = setInterval(() => {
      triggerData();
    }, 30000);

    const intervalForShow = setInterval(() => {
      if (moment().toDate().getTime() === moment(shenMorningAnnounceDate).toDate().getTime()) {
        triggerData();
      }

      if (moment().toDate().getTime() === moment(shenEveningAnnounceDate).toDate().getTime()) {
        triggerData();
      }
    }, (60 * 1000));

    return () => {
      clearInterval(interval);
      clearInterval(intervalForShow)
    }
  }, [getShenzhenMorningStatData, getShenzhenEveningStatData]);

  const triggerData = async () => {

    if (
      moment().toDate().getTime() >= moment(shenMorningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() < moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
    ) {
      // show release
      if (!shenzhenMorningCurrent) {
        const currentShenzhenMorning = await services.getCurrentPeriods(16);
        const data: PeriodType = currentShenzhenMorning.data;
        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        return setShenzhenMorningCurrent(currentShenzhenMorning.data);
      }

      const num = parseInt(upNumber.toString().substring(0, 4) + shenzhenMorningCurrent.up3) * 0.01;

      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const precisionChangedPercent = 100; // 2 decimals
      const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

      setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (shenzhenMorningCurrent.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
      return;
    }

    if (
      moment().toDate().getTime() >= moment(shenEveningAnnounceDate).toDate().getTime() &&
      moment().toDate().getTime() <= moment(moment().format("YYYY-MM-DD 23:59:59")).toDate().getTime()
    ) {
      // show release
      if (!shenzhenEveningCurrent) {
        const currentShenzhenEvening = await services.getCurrentPeriods(17);
        const data: PeriodType = currentShenzhenEvening.data;
        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        return setShenzhenEveningCurrent(currentShenzhenEvening.data);
      }

      const num = parseInt(upNumber.toString().substring(0, 4) + shenzhenEveningCurrent.up3) * 0.01;

      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const precisionChangedPercent = 100; // 2 decimals
      const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

      setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (shenzhenEveningCurrent.down2)?.toString()) * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setChangedPercent(numChangedPercent * (positiveOrNegative === "+" ? 1.00 : -1.00));
      setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
      return;
    }

    if (moment().toDate().getTime() < moment(shenMorningAnnounceDate).toDate().getTime()) {
      return randomNumber();
    }

    if (
      moment().toDate().getTime() >= moment(moment().format("YYYY-MM-DD 13:00:00")).toDate().getTime()
      && moment().toDate().getTime() < moment(shenEveningAnnounceDate).toDate().getTime()
    ) {
      return randomNumber();
    }
  }

  const shenzhenMorningData = async () => {
    if (moment().toDate().getTime() < moment(shenMorningAnnounceDate).toDate().getTime()) {
      // show null
      return setShenzhenMorningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(shenMorningAnnounceDate).toDate().getTime()) {
      // show release
      if (!shenzhenMorningCurrent) {
        const current = await services.getCurrentPeriods(16);
        return setShenzhenMorningCurrent(current.data);
      }
    }
  }

  const shenzhenEveningData = async () => {
    if (moment().toDate().getTime() < moment(shenEveningAnnounceDate).toDate().getTime()) {
      // show null
      return setShenzhenEveningCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(shenEveningAnnounceDate).toDate().getTime()) {
      // show release
      if (!shenzhenEveningCurrent) {
        const current = await services.getCurrentPeriods(17);
        return setShenzhenEveningCurrent(current.data);
      }
    }
  }

  const renderStat = () => {
    if (shenzhenMorningStat.length === 0 || shenzhenEveningStat.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>No Statistics</td>
        </tr>
      )
    }

    const stat = [];

    for (let i = 0; i < shenzhenMorningStat.length; i++) {
      const element = (
        <tr>
          <td>{moment(shenzhenMorningStat[i].dateAnnounce).format("DD/MM/YYYY")}</td>
          <td>{shenzhenMorningStat[i].up3}</td>
          <td>{shenzhenMorningStat[i].down2}</td>
          <td>{shenzhenEveningStat[i].up3}</td>
          <td>{shenzhenEveningStat[i].down2}</td>
        </tr>
      );

      stat.push(element);
    }

    return stat;
  }

  const randomNumber = () => {

    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    const precisionUp = 100; // 2 decimals
    const numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    const precisionDown = 100; // 2 decimals
    const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    const precisionChangedPercent = 100; // 2 decimals
    const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    const precisionOpen = 100; // 2 decimals
    const numOpen = Math.floor(Math.random() * (100 * precisionOpen - 1 * precisionOpen) + 1 * precisionOpen) / (1 * precisionOpen);

    const precisionPrevClose = 100; // 2 decimals
    const numPrevclose = Math.floor(Math.random() * (100 * precisionPrevClose - 1 * precisionPrevClose) + 1 * precisionPrevClose) / (1 * precisionPrevClose);

    const precisionStatMin = 100; // 2 decimals
    const numStatMin = Math.floor(Math.random() * (100 * precisionStatMin - 1 * precisionStatMin) + 1 * precisionStatMin) / (1 * precisionStatMin);

    const precisionVolumn10Bil = 100; // 2 decimals
    const numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

    const precisionVolumn100Bil = 100; // 2 decimals
    const numVolumn100Bil = Math.floor(Math.random() * (10000 * precisionVolumn100Bil - 1 * precisionVolumn100Bil) + 1 * precisionVolumn100Bil) / (1 * precisionVolumn100Bil);


    const numStatMax = (upNumber + numStatMin) + (numStatMin * precisionChangedPercent);

    setStatMin(upNumber + numStatMin);
    setStatMax(numStatMax);
    setPrevClose(upNumber + (numPrevclose * positiveOrNegativeNumber));
    setOpenNumber(upNumber + (numOpen * positiveOrNegativeNumber));
    setUpNumber(upNumber + (numUp * positiveOrNegativeNumber));
    setDownNumber(numDown * positiveOrNegativeNumberChange);
    setChangedPercent(numChangedPercent * positiveOrNegativeNumberPercent);
    setVolumn10Bil(numVolumn10Bil);
    setVolumn100Bil(numVolumn100Bil);
    setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
    setPositiveOrNegativeLast(positiveOrNegativeNumberLast === 1.00 ? "+" : "-");
    setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
    setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
  }

  const morningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td style={{ width: '30%' }}>Morning Closed</td>
        <td style={{ width: '10%' }}>Up</td>
        <td style={{ width: '20%' }}>{shenzhenMorningCurrent?.up3}</td>
        <td style={{ width: '10%' }}>Down</td>
        <td style={{ width: '20%' }}>{shenzhenMorningCurrent?.down2}</td>
      </>
    )
  }

  const eveningClose = () => {
    // if (!nikkeiMorningCurrent) {
    //   return (
    //     <>
    //       <td>Monring Closed</td>
    //       <td>Up</td>
    //       <td></td>
    //       <td>Down</td>
    //       <td></td>
    //     </>
    //   )
    // }
    return (
      <>
        <td style={{ width: '30%' }}>Evening Closed</td>
        <td style={{ width: '10%' }}>Up</td>
        <td style={{ width: '20%' }}>{shenzhenEveningCurrent?.up3}</td>
        <td style={{ width: '10%' }}>Down</td>
        <td style={{ width: '20%' }}>{shenzhenEveningCurrent?.down2}</td>
      </>
    )
  }

  return (
    <div>
      <div className="container p-0">
        <div className="cnindex">
          <div className="header">
            <img src={szseHeader} style={{ width: '100%', padding: 0 }} />
          </div>

          <div className="cn-content">
            <div className="text-center" style={{ padding: '0px 0px 40px 0px' }}>
              <span style={{ fontSize: 30, color: '#fff' }}>SZSE COMPONENT INDEX</span>
            </div>
            <div className="c-selectex-container">
              <select className="form-control" style={{ width: '30%' }}>
                <option>SZSE Component Index</option>
                <option>SZSE Composite Index</option>
                <option>SZSE SME Price Index</option>
                <option>ChiNext Index</option>
              </select>
            </div>
            <div className="content-detail">
              <div className="row m-0">
                <div className="col-xs-12 col-md-4 col-left">
                  <div className="p-2">
                    <div className="index-detail">
                      <div className="title down">{(upNumber).toFixed(2)}</div>
                      <ul>
                        <li><span className="name">Date</span><span className="value">{moment().format("MMM DD, YYYY")}</span></li>
                        <li><span className="name">Prev.Close</span> <span className="value">{(prevClose).toFixed(2)}</span></li>
                        <li><span className="name">Open</span> <span className="value">
                          {(openNumber).toFixed(2)}
                        </span></li>
                        <li className="up"><span className="name">Last</span> <span className="value change"><span className={`value-cont ${positiveOrNegative === "+" ? 'up' : 'down'}`}><FontAwesomeIcon icon={faSortDesc} style={{ verticalAlign: '0.125rem', paddingRight: 2 }} />
                          {(upNumber).toFixed(2)}
                        </span></span></li>
                        <li className="up"><span className="name">Change</span> <span className="value change"><span className={`value-cont ${positiveOrNegativeChange === "+" ? 'up' : 'down'}`}><FontAwesomeIcon icon={faSortDesc} style={{ verticalAlign: '0.125rem' }} />
                          {Math.abs(downNumber).toFixed(2)}
                        </span></span></li>
                        <li className="up"><span className="name">Change（%）</span> <span className="value change"><span className={`value-cont ${positiveOrNegativePercent === "+" ? 'up' : 'down'}`}><FontAwesomeIcon icon={faSortDesc} style={{ verticalAlign: '0.125rem' }} />
                          {Math.abs(changedPercent).toFixed(2)}
                        </span></span></li>
                        <li><span className="name">High</span> <span className="value blue">
                          {statMax.toFixed(2)}
                        </span></li>
                        <li><span className="name">Low</span> <span className="value blue">
                          {statMin.toFixed(2)}
                        </span></li>
                        <li><span className="name">Volume（10Bil.）</span> <span className="value">{volumn10Bil.toFixed(2)}</span></li>
                        <li><span className="name">Amount（100Mil.）</span> <span className="value">{volumn100Bil.toFixed(2)}</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-8">
                  <div className="pt-2 pb-2">
                    <img src={graph} width="100%" height={300} />
                  </div>
                  <div className="today-result">
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          {morningClose()}
                        </tr>
                        <tr>
                          {eveningClose()}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="history">
              <h4>Statistics</h4>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th rowSpan={2}>Date</th>
                    <th colSpan={2}>Morning Closed</th>
                    <th colSpan={2}>Evening Closed</th>
                  </tr>
                  <tr>
                    <th>Up</th>
                    <th>Down</th>
                    <th>Up</th>
                    <th>Down</th>
                  </tr>
                </thead>
                <tbody>
                  {renderStat()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App;
